<!--
 * @Author:
 * @Date: 2023-03-24 15:33:10
 * @LastEditTime: 2023-06-01 16:43:53
 * @LastEditors: 王有毒
 * @Description:
 * @FilePath: \edub_tob_pc\src\views\seventhPage\mine\learn\myOperationDetailsContent.vue
 *
-->
<template>
  <div class="myOperationDetails">
    <!-- 作业详情内容部分 -->
    <div class="myOperationDetails_content">
      <!-- 文本输入框 -->
      <template>
        <div class="myOperationDetails_content_input">
          <el-input v-if="!studentWorkDetail.commitStatus" v-model.trim="myOperationAnswers.answerText" type="textarea"
            placeholder="请输入您的答案..." />
          <el-input v-if="studentWorkDetail.checkStatus" v-model.trim="myAskContent.questionTitle" type="textarea"
            placeholder="请输入您的提问..." />
          <el-button v-if="studentWorkDetail.checkStatus" class="myOperationDetails_content_button"
            @click="myAskSubmit()">确认提问</el-button>
        </div>
        <!-- 图片上传 -->
        <div class="myOperationDetails_bottom_upload">
          <div v-for="(item, index) in imageList" v-show="index <= 9" :key="index" class="picList">
            <img class="unloadPic" :src="item" alt="" />
            <i class="el-icon-error" @click="handleRemove(item)" />
            <div class="picList_actions">
              <span class="picList_actions_preview" @click="handlePictureCardPreview(item)">
                <i class="el-icon-zoom-in" />
              </span>
              <!-- <span
                v-if="!disabled"
                class="picList_actions_delete"
                @click="handleRemove(item)"
              >
                <i class="el-icon-delete" />
              </span> -->
            </div>
          </div>
          <el-upload class="avatar-uploader" :action="imgAction()" name="upload" :headers="Domainjwt"
            list-type="picture-card" :auto-upload="true" :before-upload="beforeUpload" :on-success="loadJsonSuccess"
            :show-file-list="false" :on-remove="handleRemove" :on-preview="handlePictureCardPreview">
            <i slot="default" class="el-icon-plus avatar-uploader-icon" style="color: #707070 !important" />
          </el-upload>
          <el-dialog width="600px" class="dialogImage" style="border: none" :visible.sync="dialogVisible">
            <img :src="dialogImageUrl" alt="" />
          </el-dialog>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { getInfo, getDomainToken } from "@/api/cookies";
import { getStudentWorkList, submitWork, saveWork } from "@/api/myOp";
export default {
  components: {},
  props: {
    studentWorkDetail: {
      type: Object,
      default: () => { },
    },
    myOperationAnswers: {
      type: Object,
      default: () => { },
    },
    myAskList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      submitOperationState: 3, // 作业提交状态
      myOperationDetailsState: 4, // 作业完成状态  1未完成 2已完成未批改 3已批改 4已批改未答疑 5已答疑
      assignmentContent: null, // 作业文字内容
      dialogImageUrl: "", // 缩略图地址
      dialogVisible: false, // 缩略图弹窗
      disabled: false,
      sumbitDialogVisible: false, // 提交按钮弹窗
      // uploadFiles: [], // 图片列表
      pageSize: 1, // 页码
      pageNum: 1, // 数量
      commitStatus: 0, // 数量
      content: "",
      Domainjwt: {
        Domainjwt: getDomainToken(),
      },
      reply: {
        title: "老师评语",
        content: "dsjadsjakldjasjkldjkasjkldklasjjdklasdjaskl",
      },
      myAskContent: {
        workId: "",
        workSubjectId: "",
        questionTitle: "",
        questionImage: [],
        questionVideo: "",
        questionAudio: "",
      },
      myAsk: {
        title: "我要提问",
        name: "王有毒",
        time: "2022-12-02 15:30:22",
        content: "dsadasdasssssssssssssssssss",
      },
      imageList: [],
      userInfo: null,
    };
  },
  watch: {
    myOperationAnswers(o, p) {

      if (!o.answerImage || o.answerImage.length < 1) {
        this.imageList = [];
      } else {
        this.imageList = o.answerImage;
      }

    },
  },
  async created() {
    this.userInfo = getInfo();

    if (
      this.studentWorkDetail.checkStatus &&
      this.myAskContent.questionImage != ""
    ) {
      this.imageList = this.myAskContent.questionImage.split(",");
    } else {
      this.imageList = [];
    }
    if (
      !this.studentWorkDetail.commitStatus &&
      this.myOperationAnswers.answerImage != ""
    ) {

      this.imageList = this.myOperationAnswers.answerImage;
    } else {
      this.imageList = [];
    }
  },
  methods: {
    // 上传图片校验
    beforeUpload(file) {

      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      let isPic = isPNG || isJPG;

      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isPic) {
        this.$message.error("上传头像图片只能是 JPG或PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isLt2M && isPic;
    },
    // 上传成功
    loadJsonSuccess(res, file, fileList) {
      if (res.isSuccess == true) {
        if (this.imageList.length >= 9) {
          this.$message.error("最多上传9张图片！");
          return;
        }
        this.imageList.push(res.path);

      } else {
        this.$message.error(res.error);
      }
    },
    // 作业状态
    async getMyOperationDetailsState() {
      // try {
      //   const res = await getMyOperationDetailsState()
      //   if (res.code == 0) {
      //     this.myOperationDetailsState = res.rows.state
      //     this.reply = res.rows
      //   }
      // } catch (error) {
      //    ;
      // }dialogImage
    },
    // 删除图片
    handleRemove(item) {
      this.imageList = this.imageList.filter((items) => {
        return items !== item;
      });
      this.myOperationAnswers.answerImage = this.imageList;
    },
    // 查看缩略图
    handlePictureCardPreview(item) {
      this.dialogImageUrl = item;
      this.dialogVisible = true;
    },
    // 确认提问
    async myAskSubmit(pagesisze) {
      try {
        this.myAskContent.questionImage = this.imageList;
        this.imageList = [];
        if (this.myAskContent.questionTitle) {
          await this.$parent.submitAskQuestion(
            this.myAskList,
            this.myAskContent
          );
        } else {
          this.$message.error("提问内容不能为空！");
        }

        this.myAskContent = {
          workId: "",
          workSubjectId: "",
          questionTitle: "",
          questionImage: [],
          questionVideo: "",
          questionAudio: "",
        };
      } catch (error) {
        ;
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-icon-close {
  display: none;
}

/deep/ .el-dialog__body {
  padding-bottom: 0px !important;
}

/deep/ .el-dialog {
  background: none;
  box-shadow: none !important;

  img {
    max-width: 100%;
    margin: 0 auto;
  }
}

/deep/ .el-dialog__header {
  padding-right: 0;
  padding-top: 10px;
  padding-bottom: 0;
}

/deep/ .el-dialog__close {
  top: 0;
}

.myOperationDetails {
  font-family: PingFang SC-Regular, PingFang SC;
  width: 1200px;
  font-size: 18px;
  margin: 0 auto;

  .myOperationDetails_content {
    width: 1200px;
    background: #ffffff;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    margin-top: 14px;

    /deep/ .picList {
      width: 80px;
      position: relative;
      background-color: #fcfcfc;
      border-radius: 6px;
      margin-right: 12px;
      // cursor: pointer;
      display: flex;
      align-items: center;
      // overflow: hidden;
      width: 80px;
      height: 80px;

      // border: 1px solid #707070;
      &:hover {
        .picList_actions {
          display: block;
          display: flex;
        }
      }

      .el-icon-error {
        position: absolute;
        z-index: 999;
        right: -8px;
        top: -8px;
      }

      .unloadPic {
        width: 100%;
        height: 100%;
      }

      .picList_actions {
        display: flex;
        color: #fff;
        align-items: center;
        justify-content: space-evenly;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.4);
        backdrop-filter: 30px;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: none;
      }
    }

    .myOperationDetails_content_title {
      margin: 30px 30px 20px 30px;
    }

    .myOperationDetails_content_line {
      margin: 20px 30px 26px 30px;
      background-color: #ccc;
      height: 1px;
    }

    /deep/ .el-textarea__inner {
      margin: 0 auto;
      width: 1140px;
      height: 200px;
      margin-bottom: 15px;
      background: #f5f7f9;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
    }

    .myOperationDetails_content_input {
      margin-top: 22px;
      position: relative;

      /deep/ .el-textarea__inner {
        border: none !important;
      }

      .myOperationDetails_content_button {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 55px;
        bottom: 35px;
        width: 87px;
        height: 29px;
        opacity: 1;
        border: 1px solid #ff5e51;
        background: #f5f7f9;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ff5e51;
        line-height: 0px;
      }
    }

    /deep/ .myOperationDetails_bottom_upload {
      display: flex;
      justify-content: start;
      align-items: center;
      margin-left: 30px;
    }

    .myOperationDetails_bottom_btnBox {
      display: flex;
      margin-top: 60px;
      justify-content: space-around;
      align-items: center;
      width: 100%;

      .myOperationDetails_bottom_btnBox_left,
      .myOperationDetails_bottom_btnBox_right {
        width: 320px;
        height: 50px;
        background: #ffffff;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }
    }

    /deep/ .avatar-uploader .el-upload {
      width: 80px;
      background-color: #fcfcfc;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      width: 80px;
      height: 80px;
      opacity: 1;
      border: 1px solid #707070;
    }

    /deep/ .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }

    /deep/ .avatar-uploader-icon {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 28px;
      color: #8c939d;
      width: 80px;
      height: 80px;
      line-height: 80px;
      text-align: center;
    }

    /deep/ .el-upload-list__item {
      width: 80px;
      height: 80px;
      // display: block;
    }
  }
}
</style>
