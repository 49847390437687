var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"myAnswer"},[_c('div',{staticClass:"myAnswer_top"},[_c('div',{staticClass:"myAnswer_top_line"}),_c('div',{staticClass:"myAnswer_top_title"},[_vm._v(" "+_vm._s(_vm.checkStatus == 1 ? "老师评语" : "我的答案")+" ")])]),_c('div',{staticClass:"myAnswer_bottom"},[_c('div',{staticClass:"myAnswer_bottom_content"},[_vm._v(" "+_vm._s(_vm.checkStatus == 1 ? _vm.myOperationkCorrection.workCorrectionText : _vm.myOperationAnswers.answerText)+" ")]),_c('div',{staticClass:"myAnswer_bottom_audio",staticStyle:{"margin-bottom":"16px"}},[(
            _vm.commitStatus &&
            _vm.myOperationAnswers.answerAudio &&
            _vm.myOperationAnswers.answerAudio.length > 0
          )?_vm._l((_vm.myOperationAnswers.answerAudio),function(item,index){return _c('div',{key:index},[_c('audioPlayer',{attrs:{"audio-url":item}})],1)}):_vm._e(),(
            _vm.checkStatus &&
            _vm.myOperationkCorrection.workCorrectionAudio.length > 0
          )?_vm._l((_vm.myOperationkCorrection.workCorrectionAudio),function(item,index){return _c('div',{key:index},[_c('audioPlayer',{attrs:{"audio-url":item}})],1)}):_vm._e()],2),_c('div',{staticClass:"myAnswer_bottom_img"},[(_vm.commitStatus && _vm.myOperationAnswers.answerImage.length > 0)?_vm._l((_vm.myOperationAnswers.answerImage),function(item,index){return _c('div',{key:index,staticClass:"myAnswer_bottom_imgBox picList",staticStyle:{"width":"80px","height":"80px","overflow":"hidden","border-radius":"6px","margin-right":"16px","margin-bottom":"16px"}},[_c('el-image',{staticClass:"myAnswer_bottom_imgBox_img",attrs:{"src":item}}),_c('div',{staticClass:"picList_actions"},[_c('span',{staticClass:"picList_actions_preview",on:{"click":function($event){return _vm.handlePictureCardPreview(item)}}},[_c('i',{staticClass:"el-icon-zoom-in"})])])],1)}):_vm._e(),(
            _vm.checkStatus &&
            _vm.myOperationkCorrection.workCorrectionImage.length > 0
          )?_vm._l((_vm.myOperationkCorrection.workCorrectionImage),function(item,index){return _c('div',{key:index,staticClass:"myAnswer_bottom_imgBox picList",staticStyle:{"width":"80px","height":"80px","overflow":"hidden","border-radius":"6px","margin-right":"16px","margin-bottom":"16px"}},[_c('el-image',{staticClass:"myAnswer_bottom_imgBox_img",attrs:{"src":item}}),_c('div',{staticClass:"picList_actions"},[_c('span',{staticClass:"picList_actions_preview",on:{"click":function($event){return _vm.handlePictureCardPreview(item)}}},[_c('i',{staticClass:"el-icon-zoom-in"})])])],1)}):_vm._e()],2),(_vm.checkStatus)?_c('div',{staticClass:"myAnswer_bottom_analysis"},[_c('el-button',{staticClass:"myAnswer_bottom_analysis_btns",on:{"click":function($event){_vm.queryDialog = true}}},[_vm._v("查看解析")])],1):_vm._e(),(_vm.checkStatus == 1)?_c('div',{staticClass:"myAnswer_buttom_line"}):_vm._e()]),(_vm.checkStatus)?_c('el-dialog',{staticClass:"dialogImage",attrs:{"visible":_vm.queryDialog,"title":"查看解析"},on:{"update:visible":function($event){_vm.queryDialog=$event}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.myOperationkCorrection.questionAnswerAnalysis)}})]):_vm._e()],1),(_vm.dialogImageUrl)?_c('previewDialog',{attrs:{"dialog-image-url":_vm.dialogImageUrl}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }