<!--
 * @Description: 
 * @Version: 2.0
 * @Author: 王有毒
 * @Date: 2023-06-01 09:12:13
 * @LastEditors: 王有毒
 * @LastEditTime: 2023-06-01 14:45:53
 * @FilePath: \edub_tob_pc\src\views\seventhPage\mine\learn\previewDialog.vue
-->
<template>
  <el-dialog
    v-if="dialogImageUrl"
    class="previewDialog"
    :visible.sync="$parent.dialogVisible"
  >
    <img :src="dialogImageUrl" alt="" />
  </el-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    dialogImageUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  img {
    max-width: 100%;
    margin: 0 auto;
  }
  .el-icon-close {
    display: none;
  }
}
</style>
