<template>
  <div>
    <div class="myOperationAsk">
      <div class="myOperationAsk_top">
        <div class="myOperationAsk_top_line" />
        <div class="myOperationAsk_top_title">{{ myAsk.title }}</div>
      </div>
      <div v-for="(item, index) in myAskList" :key="index" class="myOperationAsk_bottom">
        <div class="myOperationAsk_bottom_name">
          <div class="myOperationAsk_bottom_name_imgBox" style="width: 19px; overflow: hidden">
            <el-image :src="require(`@/assets/img/homeSeventh/${item.teacherId ? 'da' : 'wen'
              }.png`)
              " fit="cover" />
          </div>
          <div class="myOperationAsk_bottom_name_text">
            {{ item.teacherId ? item.teacherName : item.studentName }}
          </div>
          <div class="myOperationAsk_bottom_name_time">
            {{ item.createTime }}
          </div>
        </div>
        <div class="myOperationAsk_bottom_content">
          {{ item.questionTitle ? item.questionTitle : item.answerTitle }}
        </div>
        <template v-if="item.answerAudio">
          <div v-for="(items, index) in item.questionAudio" :key="index">
            <audioPlayer :audio-url="items" />
          </div>
        </template>
        <template v-if="item.questionAudio">
          <div v-for="(items, index) in item.questionAudio" :key="index">
            <audioPlayer :audio-url="items" />
          </div>
        </template>
        <div class="myOperationAsk_bottom_img">
          <template v-if="item.answerImage && item.answerImage.length > 0">
            <div v-for="(items, index) in item.answerImage" :key="index" class="myOperationAsk_bottom_imgBox picList"
              style="
                width: 80px;
                height: 80px;
                overflow: hidden;
                border-radius: 6px;
                margin-top: 11px;
                margin-right: 16px;
              ">
              <el-image :src="items" class="myOperationAsk_bottom_imgBox_img" />
              <div class="picList_actions">
                <span class="picList_actions_preview" @click="handlePictureCardPreview(items)">
                  <i class="el-icon-zoom-in" />
                </span>
              </div>
            </div>
          </template>
          <template v-else>
            <div v-for="(items, index) in item.questionImage" :key="index" class="myOperationAsk_bottom_imgBox picList"
              style="
                width: 80px;
                height: 80px;
                overflow: hidden;
                border-radius: 6px;
                margin-top: 11px;
                margin-right: 16px;
              ">
              <el-image :src="items" class="myOperationAsk_bottom_imgBox_img" />
              <div class="picList_actions">
                <span class="picList_actions_preview" @click="handlePictureCardPreview(items)">
                  <i class="el-icon-zoom-in" />
                </span>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <el-dialog width="600px" class="dialogImage" style="border: none" :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import audioPlayer from "./audioPlayer.vue";
export default {
  components: { audioPlayer },
  props: {
    myAsk: {
      type: Object,
      default: () => { },
    },
    myAskList: {
      type: Array,
      default: () => [],
    },
    myOperationDetailsState: {
      type: Number,
      required: true,
      default: 3,
    },
    myAskContent: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      dialogVisible: false,
      dialogImageUrl: null,
    };
  },
  computed: {},
  watch: {
    myAskList(O, P) {
      O.filter((item) => {
        if (item.questionImage?.length > 0 && item.questionImage != "") {
          item.questionImage = item.questionImage.split(",");
        }
        if (item.answerImage?.length > 0 && item.answerImage != "") {
          1;
          item.answerImage = item.answerImage.split(",");
        }
      });
    },
  },
  created() {
    this.myAskList.filter((item) => {

      if (item.questionImage && item.questionImage != []) {
        item.questionImage = item.questionImage.split(",");
      }
      if (item.answerImage && item.answerImage != []) {
        item.answerImage = item.answerImage.split(",");
      }
      if (item.questionAudio?.length > 0 && item.questionAudio != "") {
        
        item.questionAudio = JSON.parse(item.questionAudio);
      }
      if (item.answerAudio?.length > 0 && item.answerAudio != "") {
        item.answerAudio = JSON.parse(item.answerAudio);
       
      }
    });
    
  },
  mounted() { },
  methods: {
    // 删除图片
    handleRemove(item) {
      this.imageList = this.imageList.filter((items) => {
        return items !== item;
      });
      this.myOperationAnswers.answerImage = this.imageList;
    },
    // 查看缩略图
    handlePictureCardPreview(item) {
      this.dialogImageUrl = item;
      this.dialogVisible = true;
    },
  },
};
</script>
<style lang="less" scoped>
.myOperationAsk {
  font-family: PingFang SC-Regular, PingFang SC;
  width: 1200px;
  padding: 0 30px;
  font-size: 16px;

  .myOperationAsk_top {
    display: flex;
    align-items: center;
    justify-content: start;

    .myOperationAsk_top_line {
      width: 4px;
      height: 14px;
      background: #ff5e51;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }

    .myOperationAsk_top_title {
      width: 64px;
      height: 21px;
      font-size: 16px;
      margin-left: 5px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }
  }

  .myOperationAsk_bottom {
    margin-top: 21px;

    &:last-child {
      margin-bottom: 19px;
    }

    .myOperationAsk_bottom_name {
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom: 16px;

      .myOperationAsk_bottom_name_imgBox {
        width: 19px;
        height: 19px;
        overflow: hidden;
      }

      .myOperationAsk_bottom_name_text {
        margin: 0 10px;
        font-weight: 400;
        color: #333333;
      }

      .myOperationAsk_bottom_name_time {
        font-size: 14px;
        font-weight: 400;
        // margin-bottom: 16px;
        color: #999999;
      }
    }

    .myOperationAsk_top_content {
      width: 1140px;
      height: 47px;
      margin-top: 8px;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 26px;
    }

    .myOperationAsk_bottom_img {
      display: flex;
      justify-content: start;
      align-items: center;

      .myOperationAsk_bottom_imgBox_img {
        width: 100%;
        height: 100%;
      }
    }

    .myOperationAsk_buttom_line {
      margin: 20px 30px 26px 30px;
      background-color: #ccc;
      height: 1px;
    }
  }

  /deep/ .picList {
    width: 80px;
    position: relative;
    background-color: #fcfcfc;
    border-radius: 6px;
    margin-right: 12px;
    // cursor: pointer;
    display: flex;
    align-items: center;
    // overflow: hidden;
    width: 80px;
    height: 80px;

    // border: 1px solid #707070;
    &:hover {
      .picList_actions {
        display: block;
        display: flex;
      }
    }

    .el-icon-error {
      position: absolute;
      z-index: 999;
      right: -8px;
      top: -8px;
    }

    .unloadPic {
      width: 100%;
    }

    .picList_actions {
      display: flex;
      color: #fff;
      align-items: center;
      justify-content: space-evenly;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.4);
      backdrop-filter: 30px;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: none;
    }
  }
}

/deep/ .el-dialog {
  background: none;
  border: none;
  box-shadow: none !important;

  .el-icon-close {
    display: none;
  }
}
</style>
