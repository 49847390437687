<template>
  <div>
    <div class="myAnswer">
      <div class="myAnswer_top">
        <div class="myAnswer_top_line" />
        <div class="myAnswer_top_title">
          {{ checkStatus == 1 ? "老师评语" : "我的答案" }}
        </div>
      </div>
      <div class="myAnswer_bottom">
        <div class="myAnswer_bottom_content">
          {{
            checkStatus == 1
              ? myOperationkCorrection.workCorrectionText
              : myOperationAnswers.answerText
          }}
        </div>
        <div class="myAnswer_bottom_audio" style="margin-bottom: 16px">
          <template
            v-if="
              commitStatus &&
              myOperationAnswers.answerAudio &&
              myOperationAnswers.answerAudio.length > 0
            "
          >
            <div
              v-for="(item, index) in myOperationAnswers.answerAudio"
              :key="index"
            >
              <audioPlayer :audio-url="item" />
            </div>
          </template>
          <template
            v-if="
              checkStatus &&
              myOperationkCorrection.workCorrectionAudio.length > 0
            "
          >
            <div
              v-for="(
                item, index
              ) in myOperationkCorrection.workCorrectionAudio"
              :key="index"
            >
              <audioPlayer :audio-url="item" />
            </div>
          </template>
        </div>
        <div class="myAnswer_bottom_img">
          <template
            v-if="commitStatus && myOperationAnswers.answerImage.length > 0"
          >
            <div
              v-for="(item, index) in myOperationAnswers.answerImage"
              :key="index"
              class="myAnswer_bottom_imgBox picList"
              style="
                width: 80px;
                height: 80px;
                overflow: hidden;
                border-radius: 6px;
                margin-right: 16px;
                margin-bottom: 16px;
              "
            >
              <el-image :src="item" class="myAnswer_bottom_imgBox_img" />
              <div class="picList_actions">
                <span
                  class="picList_actions_preview"
                  @click="handlePictureCardPreview(item)"
                >
                  <i class="el-icon-zoom-in" />
                </span>
              </div></div
          ></template>

          <template
            v-if="
              checkStatus &&
              myOperationkCorrection.workCorrectionImage.length > 0
            "
          >
            <div
              v-for="(
                item, index
              ) in myOperationkCorrection.workCorrectionImage"
              :key="index"
              class="myAnswer_bottom_imgBox picList"
              style="
                width: 80px;
                height: 80px;
                overflow: hidden;
                border-radius: 6px;
                margin-right: 16px;
                margin-bottom: 16px;
              "
            >
              <el-image :src="item" class="myAnswer_bottom_imgBox_img" />
              <div class="picList_actions">
                <span
                  class="picList_actions_preview"
                  @click="handlePictureCardPreview(item)"
                >
                  <i class="el-icon-zoom-in" />
                </span>
              </div></div
          ></template>
        </div>
        <div v-if="checkStatus" class="myAnswer_bottom_analysis">
          <el-button
            class="myAnswer_bottom_analysis_btns"
            @click="queryDialog = true"
            >查看解析</el-button
          >
        </div>
        <div v-if="checkStatus == 1" class="myAnswer_buttom_line" />
      </div>
      <el-dialog
        v-if="checkStatus"
        :visible.sync="queryDialog"
        title="查看解析"
        class="dialogImage"
      >
        <div v-html="myOperationkCorrection.questionAnswerAnalysis" />
      </el-dialog>
    </div>
    <previewDialog v-if="dialogImageUrl" :dialog-image-url="dialogImageUrl" />
  </div>
</template>

<script>
import audioPlayer from "./audioPlayer.vue";
import previewDialog from "./previewDialog.vue";
export default {
  components: { audioPlayer, previewDialog },
  props: {
    myOperationkCorrection: {
      type: Object,
      default: () => {},
    },
    myOperationAnswers: {
      type: Object,
      default: () => {},
    },
    checkStatus: {
      type: Number,
      default: 0,
    },
    commitStatus: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      queryDialog: false,
      dialogVisible: false,
      dialogImageUrl: null,
    };
  },
  computed: {},
  watch: {
    myOperationAnswers(n, o) {
      if (!n.answerImage) {
        this.myOperationAnswers.answerImage = [];
      }
      if (!n.answerAudio) {
        this.myOperationAnswers.answerAudio = [];
      }
    },
  },
  created() {
    
    if (this.myOperationAnswers && this.myOperationAnswers.answerAudio) {
      // this.myOperationAnswers.answerAudio = JSON.parse(this.myOperationAnswers.answerAudio)
    }
  },
  mounted() {
    
  },
  methods: {
    handlePictureCardPreview(item) {
      this.dialogImageUrl = item;
      this.dialogVisible = true;
    },
  },
};
</script>
<style lang="less" scoped>
.myAnswer {
  font-family: PingFang SC-Regular, PingFang SC;
  width: 1200px;
  padding: 0 30px;
  font-size: 16px;
  .myAnswer_top {
    display: flex;
    align-items: center;
    justify-content: start;
    .myAnswer_top_line {
      width: 4px;
      height: 14px;
      background: #ff5e51;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
    .myAnswer_top_title {
      width: 64px;
      height: 21px;
      font-size: 16px;
      margin-left: 5px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }
  }
  .myAnswer_bottom {
    margin-top: 16px;
    .myAnswer_top_content {
      width: 1140px;
      height: 47px;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 26px;
    }
    .myAnswer_bottom_img {
      display: flex;
      justify-content: start;
      align-items: center;
      .myAnswer_bottom_imgBox_img {
        width: 100%;
        height: 100%;
      }
    }
    .myAnswer_buttom_line {
      margin: 20px 0px 26px;
      background-color: #e7e7e7;
      height: 1px;
    }
  }
  .myAnswer_bottom_analysis_btns {
    // margin-top: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 87px;
    height: 29px;
    color: #ff5e51;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #ff5e51;
  }
  /deep/ .picList {
    width: 80px;
    position: relative;
    background-color: #fcfcfc;
    border-radius: 6px;
    margin-right: 12px;
    // cursor: pointer;
    display: flex;
    align-items: center;
    // overflow: hidden;
    width: 80px;
    height: 80px;
    // border: 1px solid #707070;
    &:hover {
      .picList_actions {
        display: block;
        display: flex;
      }
    }
    .el-icon-error {
      position: absolute;
      z-index: 999;
      right: -8px;
      top: -8px;
    }
    .unloadPic {
      width: 100%;
    }
    .picList_actions {
      display: flex;
      color: #fff;
      align-items: center;
      justify-content: space-evenly;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.4);
      backdrop-filter: 30px;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: none;
    }
  }
}
/deep/ .el-dialog__body {
  padding: 24px !important;
}
/deep/ .el-dialog {
  height: 50%;
}
/deep/ .el-icon-close {
  display: none;
}
</style>
