<!--
 * @Author:
 * @Date: 2023-03-24 15:33:10
 * @LastEditTime: 2023-06-08 11:27:05
 * @LastEditors: 王有毒
 * @Description:
 * @FilePath: \edub_tob_pc\src\views\seventhPage\mine\learn\myOperationDetails.vue
 *
-->
<template>
  <div class="myOperationDetails">
    <!-- 作业详情头部部分 -->
    <div class="myOperationDetails_top">
      <div class="myOperationDetails_top_left">
        <!-- 作业题目 -->
        <div v-if="StudentWorkDetail.workWay == 2" class="myOperationDetails_top_left_title">
          <div class="myOperationDetails_top_left_title_docBox">
            <img class="myOperationDetails_top_left_title_doc" src="@/assets/img/homeSeventh/doc.png" alt="" />
            <div style="margin-left: 12px">
              <span> {{ StudentWorkDetail.workFileName }}</span>
              <br />
              <img class="myOperationDetails_top_left_title_download" src="@/assets/img/homeSeventh/download.png"
                alt="" /><a class="myOperationDetails_top_left_title_text" :href="StudentWorkDetail.workFile">下载</a>
            </div>
          </div>
        </div>
        <div v-else class="myOperationDetails_top_left_title" v-html="StudentWorkDetail.workName" />
        <div v-if="StudentWorkDetail.workWay != 2" class="myOperationDetails_top_left_pageSize">
          <span style="color: red">{{ pageSize }}</span>{{ `/${myOperationDetailsList.length}` }}
        </div>
      </div>
      <div class="myOperationDetails_top_right">
        <!-- 提交按钮 -->
        <el-button v-if="!StudentWorkDetail.commitStatus" class="myOperationDetails_top_right_btn"
          @click="submitOperation()">提交作业</el-button>
        <el-image v-else-if="StudentWorkDetail.checkStatus && StudentWorkDetail.grade == 3
          " class="myOperationDetails_top_right_img" :src="require('@/assets/img/homeSeventh/grade3.png')" />
        <el-image v-else-if="StudentWorkDetail.checkStatus && StudentWorkDetail.grade == 2
          " class="myOperationDetails_top_right_img" :src="require('@/assets/img/homeSeventh/grade2.png')" />
        <el-image v-else-if="StudentWorkDetail.checkStatus && StudentWorkDetail.grade == 1
          " class="myOperationDetails_top_right_img" :src="require('@/assets/img/homeSeventh/grade1.png')" />
        <el-image v-else-if="StudentWorkDetail.checkStatus && StudentWorkDetail.grade == 0
          " class="myOperationDetails_top_right_img" :src="require('@/assets/img/homeSeventh/grade0.png')" />
      </div>
    </div>
    <!-- 作业详情内容部分 -->
    <div class="myOperationDetails_bottom">
      <!-- 作业内容 -->
      <div v-if="StudentWorkDetail.workWay != 2" class="myOperationDetails_buttom_title"
        v-html="myOperationDetails.questionTitle" />
      <div class="myOperationDetails_buttom_line" />
      <!-- 我的答案 -->
      <myOperationComment v-if="StudentWorkDetail.commitStatus && myOperationAnswers" ref="myOperationComment"
        :commit-status="StudentWorkDetail.commitStatus" :my-operation-answers="myOperationAnswers" />
      <!-- 老师评语 -->
      <myOperationComment v-if="StudentWorkDetail.checkStatus && myOperationkCorrection"
        :check-status="StudentWorkDetail.checkStatus" :my-operationk-correction="myOperationkCorrection" />
      <!-- 我的提问 -->
      <myOperationAsk v-if="StudentWorkDetail.checkStatus && askShow" :my-operation-details-state="4"
        :my-ask-list="myAskList" :my-ask-content="myAskContent" :my-ask="myAsk" />
      <!-- 答案文本输入框 -->
      <myOperationDetailsContent v-if="!StudentWorkDetail.commitStatus && myAnswerShow" ref="myOperationDetailsContent"
        :my-operation-answers="myOperationAnswers" :student-work-detail="StudentWorkDetail" />
      <!-- 答疑文本输入框 -->
      <myOperationDetailsContent v-if="StudentWorkDetail.checkStatus" :my-ask-content="myAskContent"
        ref="myOperationDetailsContent2" :student-work-detail="StudentWorkDetail" />
      <div class="myOperationDetails_bottom_btnBox">
        <el-button class="myOperationDetails_bottom_btnBox_left" @click="previousTopic"><i
            class="iconfont el-icon-arrow-left" /> 上一题</el-button>
        <el-button class="myOperationDetails_bottom_btnBox_right" @click="NextTopic">下一题 <i
            class="iconfont el-icon-arrow-right" /></el-button>
      </div>
    </div>
    <!-- 作业提交弹窗 -->
    <myOperationDetailsDialog :visible.sync="sumbitDialogVisible" :commit-status="myOperationAnswersList.length == myOperationDetailsList.length
      " my-operation-answers-list="myOperationAnswersList" />
  </div>
</template>
<script>
import myOperationDetailsDialog from "./myOperationDetailsDialog.vue";
import myOperationAsk from "./myOperationAsk.vue";
import myOperationComment from "./myOperationComment.vue";
import myOperationDetailsContent from "./myOperationDetailsContent.vue";
import { getInfo } from "@/api/cookies";
import {
  getStudentWorkList,
  submitWork,
  getStudentWorkDetail,
  saveWork,
  submitAskQuestion,
} from "@/api/myOp";
myOperationAsk;
export default {
  beforeRouteLeave(to, from, next) {
    // 这里需要elementui的支持，如果使用其他界面组件自行替换即可
    if (!this.StudentWorkDetail.commitStatus) {
      this.$confirm("作业未提交，是否保存进度并退出？", "提醒", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: "messageBox",
      })
        .then(async () => {
          await this.save(0);
          next();
        })
        .catch(() => {
          // 如果取消跳转地址栏会变化，这时保持地址栏不变
          next();
        });
    } else {
      next();
    }
    // next()
  },
  components: {
    myOperationDetailsDialog,
    myOperationComment,
    myOperationAsk,
    myOperationDetailsContent,
  },
  data() {
    return {
      sumbitDialogVisible: false, // 提交按钮弹窗
      // uploadFiles: [], // 图片列表
      pageSize: 1, // 页码
      pageNum: 1, // 数量
      myAskList: [],
      workId: null,
      /*  */
      /* 当前作業詳情 */
      myOperationDetails: {
        questionTitle: "",
        status: "",
        workId: "",
        workSubjectId: "",
        answerText: "",
        answerAudio: "",
        answerImage: [],
      },
      myOperationkCorrection: {
        workSubjectId: "",
        workCorrectionDocument: "",
        questionAnswerAnalysis: "",
        workCorrectionAudio: "",
        workCorrectionText: "",
        workCorrectionImage: [],
      },
      askShow: false,
      myOperationAnswers: {
        workSubjectId: "",
        answerText: "",
        answerAudio: [],
        answerImage: [],
      },
      myOperationkCorrectionList: [],
      myOperationDetailsList: [],
      myOperationAnswersList: [],
      /* 所有作业详情 */
      StudentWorkDetail: {},
      myAsk: {
        title: "我要提问",
        name: "王有毒",
        time: new Date(),
      },
      myAnswerShow: false,
      userInfo: null,
      myAskContent: {
        workId: "",
        subjectId: "",
        questionTitle: "",
        questionImage: [],
        questionVideo: "",
        questionAudio: "",
      },
    };
  },
  async created() {
    this.userInfo = getInfo();
    
    this.workId = this.$route.query.workId;
    await this.getStudentWorkDetail();
  },
  methods: {
    /* 获取作业详情 */
    async getStudentWorkDetail() {
      try {
        const res = await getStudentWorkDetail(this.workId);
        if (res) {
          this.StudentWorkDetail = res.data;
          this.myOperationDetailsList = res.data.workSubjectVoList;
          this.myOperationDetailsList.forEach((element) => {
            const regex = new RegExp("<img", "gi");
            if (element.questionTitle) {
              element.questionTitle = element.questionTitle.replace(
                regex,
                `<img style="max-width: 100%;height:auto;display:block;margin:10px 0;"`
              );
            }
          });
          this.myOperationDetails =
            res.data.workSubjectVoList[this.pageSize - 1];
          // this.getMyOperationTopic(this.myOperationDetails)
          this.myOperationAnswersList = res.data.workSubjectVoList.map(
            (item) => {
              return Object.assign(
                {},
                {
                  answerText: item.answerText,
                  answerImage: item.answerImage
                    ? item.answerImage.split(",")
                    : "",
                  answerAudio: item.answerAudio
                    ? JSON.parse(item.answerAudio)
                    : "",
                  workSubjectId: item.workSubjectId,
                }
              );
            }
          );
          this.myOperationAnswers =
            this.myOperationAnswersList[this.pageSize - 1];
          if (this.StudentWorkDetail.workWay == 2) {
            this.myOperationAnswers = {
              answerText: res.data.workSubjectVoList[0]?.answerText || "",
              answerImage: res.data.workSubjectVoList[0]?.answerImage
                ? res.data.workSubjectVoList[0].answerImage.split(",")
                : "",
              answerAudio: res.data.workSubjectVoList[0]?.answerAudio
                ? JSON.parse(res.data.workSubjectVoList[0].answerAudio)
                : "",
              workSubjectId: res.data.workSubjectVoList[0]?.workSubjectId || 0,
            };
          }
          if (res.data.checkStatus) {
            // this.getMyOperationkCorrection(this.myOperationDetails)
            this.myOperationkCorrectionList = res.data.workSubjectVoList.map(
              (item) => {
                return Object.assign(
                  {},
                  {
                    workCorrectionText: item.workCorrectionText,
                    questionAnswerAnalysis: item.questionAnswerAnalysis,
                    workCorrectionImage: item.workCorrectionImage
                      ? item.workCorrectionImage.split(",")
                      : "",
                    workCorrectionAudio: item.workCorrectionAudio
                      ? JSON.parse(item.workCorrectionAudio)
                      : "",
                    workSubjectId: item.workSubjectId,
                  }
                );
              }
            );
            this.myOperationkCorrection =
              this.myOperationkCorrectionList[this.pageSize - 1];
          }
          if (
            this.myOperationDetails &&
            this.myOperationDetails.workSubjectQueryVoList.length > 0
          ) {
            this.myAskList = this.myOperationDetails.workSubjectQueryVoList;
            this.askShow = true;

            this.$forceUpdate();
          }
          this.myAnswerShow = true;
        }
      } catch (error) {
        ;
      }
    },
    /* 保存作业  主动退出保存   提交作业保存 */
    async save(submit) {
      try {
        if (!this.StudentWorkDetail.commitStatus) {
          const list = this.$refs.myOperationDetailsContent.myOperationAnswers;
          list.answerImage = this.$refs.myOperationDetailsContent.imageList;
          // this.myOperationAnswers = this.$refs.myOperationDetailsContent.myOperationAnswers
          if (this.StudentWorkDetail.workWay == 2) {
            list.workSubjectId = 0;
          } else {
            list.workSubjectId = this.myOperationDetails.workSubjectId;
          }
          if (this.myOperationAnswersList.length > 0) {
            this.myOperationAnswersList.filter((item, index) => {
              if (item.workSubjectId == this.myOperationAnswers.workSubjectId) {
                this.$set(this.myOperationAnswersList, index, list);
              }
            });
          } else {
            this.myOperationAnswersList.push(list);
          }
          if (submit == 2) {
            return;
          } else if (submit) {
            this.myOperationAnswersList.filter((item) => {
              if (item.answerImage instanceof Array) {
                item.answerImage = item.answerImage.join(",");
              } else {
                item.answerImage = "";
              }
              if (item.answerAudio instanceof Array) {
                item.answerAudio = item.answerAudio.join(",");
              } else {
                item.answerAudio = "";
              }
            });
            const data = {
              workId: this.workId,
              submitSubjectPoList: this.myOperationAnswersList,
            };
            await submitWork(data);
            this.$message.success("作业提交成功！");
            await this.getStudentWorkDetail();
          } else {
            this.myOperationAnswersList.filter((item) => {
              if (item.answerImage instanceof Array) {
                item.answerImage = item.answerImage.join(",");
              } else {
                item.answerImage = "";
              }
              if (item.answerAudio instanceof Array) {
                item.answerAudio = item.answerAudio.join(",");
              } else {
                item.answerAudio = "";
              }
            });
            const data = {
              workId: this.workId,
              submitSubjectPoList: this.myOperationAnswersList,
            };
            await saveWork(data);
            await this.getStudentWorkDetail();
          }
        }
      } catch (error) {
        ;
      }
      // this.myOperationAnswers.workSubjectId = items.workSubjectId
    },
    /* 提交作业弹窗 */
    async submitOperation() {
      const completeState = this.myOperationAnswersList.every((item) => {
        return item.answerText;
      });
      if (!completeState) {
        this.assignmentContent = "有题目未完成，确认是否提交作业？";
      } else {
        this.assignmentContent = "题目已全部完成，确认是否提交作业？";
      }
      this.$forceUpdate();
      this.sumbitDialogVisible = true;
    },
    /* 学生答疑 */
    async submitAskQuestion(myAskList, myAskContent) {
      try {
        // this.myAskList = myAskList
        if (myAskContent.questionImage.length > 0) {
          myAskContent.questionImage = myAskContent.questionImage.join(",");
        } else {
          myAskContent.questionImage = "";
        }
        myAskContent.subjectId = this.myOperationDetails.workSubjectId;
        myAskContent.workId = this.workId;
        const res = await submitAskQuestion(myAskContent);
        await this.getStudentWorkDetail();
        this.$forceUpdate();
      } catch (error) {
        ;
      }
    },
    // 下一题
    async NextTopic() {

      if (this.StudentWorkDetail.checkStatus) {
        this.$refs.myOperationDetailsContent2.myAskContent = {
          workId: "",
          workSubjectId: "",
          questionTitle: "",
          questionImage: [],
          questionVideo: "",
          questionAudio: "",
        };
        this.$refs.myOperationDetailsContent2.imageList = [];
      }
      // this.myAskContent.questionImage = [];
      // this.myAskContent.questionAudio = "";
      // this.myAskContent.questionTitle = "";
      if (this.pageSize < this.myOperationDetailsList.length) {
        // 如果还下一题保存当前题
        await this.save(2);
        this.pageSize++;
        this.myOperationDetails =
          this.myOperationDetailsList[this.pageSize - 1];
        this.myOperationAnswers =
          this.myOperationAnswersList[this.pageSize - 1];
        this.myAskList = this.myAskList[this.pageSize - 1];
        this.myOperationkCorrection =
          this.myOperationkCorrectionList[this.pageSize - 1];

        if (this.StudentWorkDetail.commitStatus) {
          this.myAskList = this.myOperationDetails.workSubjectQueryVoList;
        }
        // this.getMyOperationTopic(this.myOperationDetails)
      } else {
        this.pageSize = this.myOperationDetailsList.length;
        this.$message.error("已经是最后一题了");
      }
    },
    // 上一题
    async previousTopic() {
      if (this.StudentWorkDetail.checkStatus) {
        this.$refs.myOperationDetailsContent2.myAskContent = {
          workId: "",
          workSubjectId: "",
          questionTitle: "",
          questionImage: [],
          questionVideo: "",
          questionAudio: "",
        };
        this.$refs.myOperationDetailsContent2.imageList = [];
      }
      this.myAskContent = {
        workId: "",
        subjectId: "",
        questionTitle: "",
        questionImage: [],
        questionVideo: "",
        questionAudio: "",
      };
      if (this.pageSize <= 1) {
        this.pageSize = 1;
        this.$message.error("已经是第一题了");
        await this.save(2);
        // this.getStudentWorkDetail()
        // return
      } else {
        await this.save(2);
        this.pageSize--;
        this.myOperationDetails =
          this.myOperationDetailsList[this.pageSize - 1];
        this.myOperationAnswers =
          this.myOperationAnswersList[this.pageSize - 1];
        this.myOperationkCorrection =
          this.myOperationkCorrectionList[this.pageSize - 1];
        if (this.StudentWorkDetail.commitStatus) {
          this.myAskList = this.myOperationDetails.workSubjectQueryVoList;
        }
      }
    },
  },
};
</script>
<style>
.messageBox {
  width: 420px;
  height: 276px;
  background: #ffffff;
  text-align: center;
  border-radius: 12px 12px 12px 12px;
}

.messageBox .el-message-box__header {
  padding-top: 29px;
  text-align: center;
  padding-bottom: 0;
}

.messageBox .el-message-box__content {
  padding-top: 53px;
  text-align: center;
  padding-bottom: 0;
}

.messageBox .el-message-box__btns {
  padding-top: 71px;
  text-align: center;
}

.messageBox .el-button {
  font-size: 16px;
  width: 140px;
  height: 44px;
  background: #fff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
}

.messageBox .el-button:hover {
  /* border: none; */
}

.messageBox .el-button:focus {
  border: none;
}

.messageBox .el-button--primary {
  border: none;
  background-color: #ff5e51;
}

.messageBox .el-icon-warning {
  display: none;
}
</style>
<style lang="less" scoped>
.myOperationDetails {
  font-family: PingFang SC-Regular, PingFang SC;
  width: 1200px;
  font-size: 18px;
  margin: 0 auto;

  .myOperationDetails_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1200px;
    min-height: 98px;
    margin-top: 20px;
    background: #ffffff;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;

    .myOperationDetails_top_left {
      display: flex;
      align-items: center;
      justify-content: start;
      margin-left: 30px;

      .myOperationDetails_top_left_title {
        font-size: 18px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #333333;

        .myOperationDetails_top_left_title_docBox {
          display: flex;
          align-items: center;
          justify-content: start;

          .myOperationDetails_top_left_title_download {
            display: inline-block;
            vertical-align: middle;
            margin-right: 5px;
            width: 18px;
            height: 18px;
          }

          .myOperationDetails_top_left_title_text {
            display: inline-block;
            margin-top: 13px;
            line-height: 0;
            margin-right: 5px;
            height: 18px;
          }

          .myOperationDetails_top_left_title_doc {
            display: inline-block;
            width: 60px;
            height: 60px;
          }

          a {
            margin-top: 10px;
            font-size: 18px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500 !important;
            color: #1c81f5 !important;
          }
        }
      }

      .myOperationDetails_top_left_pageSize {
        margin-left: 10px;
        color: #999;
      }
    }

    .myOperationDetails_top_right {
      margin-right: 30px;

      .myOperationDetails_top_right_btn {
        width: 130px;
        height: 40px;
        background: #ff5e51;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 0px;
      }

      .myOperationDetails_top_right_img {
        margin-top: 7px;
        width: 85px;
        height: 85px;
        background: #ffffff;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }
    }
  }

  .myOperationDetails_bottom {
    padding-top: 30px;
    width: 1200px;
    background: #ffffff;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    margin-top: 14px;

    /deep/ .picList {
      width: 80px;
      position: relative;
      background-color: #fcfcfc;
      border-radius: 6px;
      margin-right: 12px;
      // cursor: pointer;
      display: flex;
      align-items: center;
      width: 80px;
      height: 80px;
      opacity: 1;

      // border: 1px solid #707070;
      &:hover {
        .picList_actions {
          display: block;
          display: flex;
        }
      }

      .unloadPic {
        width: 100%;
      }

      .picList_actions {
        display: flex;
        color: #fff;
        align-items: center;
        justify-content: space-evenly;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.4);
        backdrop-filter: 30px;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: none;
      }
    }

    /deep/ .myOperationDetails_buttom_title {
      margin: 0px 30px 20px 30px;
      font-size: 18px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;

      img {
        max-width: 800px;
      }
    }

    .myOperationDetails_buttom_line {
      margin: 20px 30px 26px 30px;
      background-color: #eeeeee;
      height: 1px;
    }

    /deep/ .el-textarea__inner {
      margin: 0 auto;
      width: 1140px;
      height: 200px;
      margin-bottom: 15px;
      background: #f5f7f9;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
    }

    .myOperationDetails_buttom_input {
      margin-top: 22px;
      position: relative;

      .myOperationDetails_buttom_button {
        position: absolute;
        right: 55px;
        bottom: 35px;
        width: 87px;
        height: 29px;
        opacity: 1;
        border: 1px solid #ff5e51;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ff5e51;
        line-height: 0px;
      }
    }

    /deep/ .myOperationDetails_bottom_upload {
      display: flex;
      justify-content: start;
      align-items: center;
      margin-left: 30px;
    }

    .myOperationDetails_bottom_btnBox {
      display: flex;
      margin-top: 60px;
      border-top: 1px solid #eeeeee;
      justify-content: space-around;
      align-items: center;
      width: 100%;

      .myOperationDetails_bottom_btnBox_left,
      .myOperationDetails_bottom_btnBox_right {
        flex: 1;
        width: 320px;
        height: 50px;
        border: none;
        margin-left: 0;
        background: #ffffff;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }
    }

    /deep/ .avatar-uploader .el-upload {
      width: 80px;
      background-color: #fcfcfc;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      width: 80px;
      height: 80px;
      opacity: 1;
      // border: 1px solid #707070;
    }

    /deep/ .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }

    /deep/ .avatar-uploader-icon {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 28px;
      color: #8c939d;
      width: 80px;
      height: 80px;
      line-height: 80px;
      text-align: center;
    }

    /deep/ .el-upload-list__item {
      width: 80px;
      height: 80px;
      // display: block;
    }
  }
}
</style>
